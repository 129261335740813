<template>
  <div>
    <c-table
      ref="table"
      title="개선 이행률 - 업무별"
      tableId="impr-rate-task"
      :columns="grid.columns"
      :data="grid.data"
      :showDescription="true"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="검색" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template slot="description-td" slot-scope="props">
        <div class="row" style="align-items:center">
          <!-- 개선처리건 진행도 -->
          <div class="col-xs-12 col-sm-12 col-md-10 col-lg-10 impr-item">
            <div class="pt-0 pb-0 card-body">
              <ul class="list-group list-group-flush">
                <li class="no-padding list-group-item">
                  <div class="widget-content p-0">
                    <div class="widget-content-outer">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left"> 
                          <div class="widget-heading">{{props.row.ibmTaskTypeName}} 개선처리건 진행도</div>
                          <div class="widget-subheading">
                            <b>완료 건 수 / 요청 건 수</b> : {{props.row.complete | toThousandFilter}} / {{props.row.requestTotal | toThousandFilter}}
                          </div>
                        </div>
                        <div class="widget-content-right">
                          <div class="widget-numbers text-focus">
                            {{setPercent(props.row.requestTotal, props.row.complete)}}%
                          </div>
                        </div>
                      </div>
                      <div class="no-margin widget-progress-wrapper">
                        <div class="progress-bar-sm progress-bar-animated-alt progress">
                          <div class="progress-bar bg-success" role="progressbar" aria-valuenow="43"
                            aria-valuemin="0" aria-valuemax="100" :style="{'width': setPercent(props.row.requestTotal, props.row.complete) + '%' }">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <!-- 즉시처리건 진행도 -->
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 impr-item">
            <div class="pt-0 pb-0 card-body">
              <ul class="list-group list-group-flush">
                <li class="no-padding list-group-item">
                  <div class="widget-content p-0">
                    <div class="widget-content-outer">
                      <div class="widget-content-wrapper">
                        <div class="widget-content-left">
                          <div class="widget-heading">{{props.row.ibmTaskTypeName}}</div>
                          <div class="widget-subheading">
                            즉시처리건
                          </div>
                        </div>
                        <div class="widget-content-right">
                          <div class="widget-numbers text-focus" style="text-align: end;">
                            <span class="count-up-wrapper-2 text-focus" style="font-weight:800; padding-right: 5px;">
                              <ICountUp :startVal="0" :endVal="props.row.immTotal" :decimals="0"
                                :duration="4" :options="options" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import ICountUp from "vue-countup-v2";
import selectConfig from '@/js/selectConfig';
export default {
  name: 'impr-task-rate',
  components: {
    ICountUp
  },
  props: {
    searchParam: {
      type: Object,
      default: function() {
        return {
          plantCd: null,
          actionCompleteRequestDates: [],
          actionDeptCd: '',
        }
      },
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'ibmTaskTypeName',
            field: 'ibmTaskTypeName',
            label: '업무',
            align: 'center',
            style: 'width:120px',
            sortable: false,
            type: 'link',
          },
          {
            name: 'impr',
            field: 'impr',
            label: '개선처리건',
            align: 'center',
            child: [
              {
                name: 'requesting',
                field: 'requesting',
                label: '요청중',
                align: 'right',
                style: 'width:80px',
                sortable: false,
                type: 'cost',
              },
              {
                name: 'receipting',
                field: 'receipting',
                label: '조치부서 접수중',
                align: 'right',
                style: 'width:80px',
                sortable: true,
                type: 'cost',
              },
              {
                name: 'actioning',
                field: 'actioning',
                label: '조치부서 조치중',
                align: 'right',
                style: 'width:80px',
                sortable: true,
                type: 'cost',
              },
              {
                name: 'confirming',
                field: 'confirming',
                label: '요청부서 검토중',
                align: 'right',
                style: 'width:80px',
                sortable: true,
                type: 'cost',
              },
              {
                name: 'comp',
                field: 'comp',
                label: '개선완료',
                child: [
                  {
                    name: 'supple',
                    field: 'supple',
                    label: '보완',
                    align: 'right',
                    style: 'width:80px',
                    sortable: true,
                    type: 'cost',
                  },
                  {
                    name: 'fit',
                    field: 'fit',
                    label: '적합',
                    align: 'right',
                    style: 'width:80px',
                    sortable: true,
                    type: 'cost',
                  },
                  {
                    name: 'noAction',
                    field: 'noAction',
                    label: '미처리',
                    align: 'right',
                    style: 'width:80px',
                    sortable: true,
                    type: 'cost',
                  },
                  {
                    name: 'notYet',
                    field: 'notYet',
                    label: '주시중',
                    align: 'right',
                    style: 'width:80px',
                    sortable: true,
                    type: 'cost',
                  },
                  {
                    name: 'complete',
                    field: 'complete',
                    label: '총합',
                    align: 'right',
                    style: 'width:80px',
                    sortable: true,
                    type: 'cost',
                  },
                ]
              },
              {
                name: 'overAction',
                field: 'overAction',
                label: '지연 건수',
                align: 'right',
                style: 'width:80px',
                sortable: true,
                type: 'cost',
              },
            ]
          },
          {
            name: 'immTotal',
            field: 'immTotal',
            label: '즉시처리건',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            type: 'cost',
          },
        ],
        data: [],
      },
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "건"
      },
      editable: true,
      listUrl: '',
      curSearchParam: {
        plantCd: null,
        actionCompleteRequestDates: [],
        actionDeptCd: '',
        ibmTaskTypeCd: '',
      },
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.ibm.improve.status.task.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.$_.extend(this.curSearchParam, this.searchParam)
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '[' + row.ibmTaskTypeName + '] 업무별 개선 이행률 상세'; // 업무별 개선 이행률 상세
      this.curSearchParam.ibmTaskTypeCd = row.ibmTaskTypeCd;
      this.popupOptions.param = {
        tab: 'task',
        clickInfo: row.ibmTaskTypeName,
        data: row,
        searchParam: this.curSearchParam,
      };
      this.popupOptions.target = () => import(`${'./imprActionRateDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
    },
    setPercent(total, complete) {
      return total > 0 ? this.$_.round((complete / total) * 100, 2) : 0
    },
  }
};
</script>
<style scoped>
.widget-numbers {
  font-size: 20px;
}
.impr-item {
  height: 100% !important;
}
</style>